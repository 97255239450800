@import '~@ui/styles/tools';

.container {
    padding: 20px;
    border-radius: 12px;
    background: #fff;
    margin-bottom: 20px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: top right;
}

.content {
    max-width: 500px;
}

.form {
    & label {
        > div {
            height: 54px;
        }
    }

    & form span {
        max-width: 200px;
    }

   & button {
        height: 54px;
        min-width: 200px !important;
        max-width: 200px;
        padding: 16px 24px 20px !important;
   }

   & span {
        font-size: 16px;
        line-height: 16px;  
   }
}

.title {
    font-size: 26px;
    font-weight: 600;
    line-height: 37px;
    text-align: left;
    margin-bottom: 20px;
}

.privacy-block {
    font-size: 14px;
    font-weight: 400;
    line-height: 19.6px;
    letter-spacing: 0.3px;
    color: #64696C;

    & a {
        color: #64696C;
        text-decoration: underline;
    }
}

@include media-mobile {
    .container {
        background-position: bottom;
    }

    .form {
        margin-bottom: 15px;

        & label {
            margin-bottom: 10px;
        }
    
        & form {
            gap: 0;

            & > span {
                max-width: 100%;
                width: 100%;
                padding: 0;
                margin: 0;
            }
        }

        & button {
            min-width: 100% !important;
            max-width: 100%;
        }
    }
}